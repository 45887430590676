import "./digitalbanner.css";

const DigitalBanner = () => {
  return (
    <>
      <div className="digital-banner-container">
        <div className="banner-text-overlay">Digital Marketing</div>
      </div>
    </>
  );
};
export default DigitalBanner;
