import "./Banner.css";

const Banner = () => {
  return (
    <>
      <div className="banner-container"></div>
    </>
  );
};
export default Banner;
