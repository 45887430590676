import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import seoImg from "../assets/images/seo.png"; // Add relevant images for new sections
import socialMediaImg from "../assets/images/social media management.png";
import localSeoImg from "../assets/images/local seo.png";
import marketingCampaignsImg from "../assets/images/marketing campaign.png";
import ppcManagementImg from "../assets/images/ppc.png";
import Banner from "../components/Service/banner/DigitalBanner/digitalBanner";

const DigitalMarketingPage = () => {
  return (
    <div
      style={{ backgroundColor: "white", color: "#4A4A4A", padding: "1rem 0" }}
    >
      {/* Banner Section */}
      <Banner />
      <Container>
        {/* Existing Designing Sections */}
        {/* (Add the existing design sections here) */}

        <h2
          className="text-center"
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#052C65",
            marginTop: "2rem",
          }}
        >
          Digital Marketing
        </h2>
        <p
          style={{
            fontSize: "1rem",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Digital marketing encompasses online strategies to promote products
          and services. It includes social media marketing, search engine
          optimization (SEO). By leveraging data analytics, businesses can
          target audiences effectively, enhance brand visibility, and drive
          conversions.{" "}
        </p>

        {/* SEO Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>1.</span>{" "}
              <span style={{ color: "#052C65" }}>
                Search Engine Optimization
              </span>
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software, based in Zirakpur, Punjab, is a leading digital
              marketing company specializing in SEO, SEM, website development,
              and branding. They focus on boosting website visibility, driving
              targeted traffic, and increasing ROI through structured,
              customized SEO campaigns tailored to clients' long-term goals.
              With an expert team, KB Software enhances profits by employing
              effective internet marketing and search engine strategies to
              ensure a strong online presence.
            </p>
          </Col>
          <Col md={6}>
            <img src={seoImg} alt="SEO" className="img-fluid rounded" />
          </Col>
        </Row>

        {/* Social Media Management Section */}
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>2.</span> Social Media Management
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software, based in Zirakpur, India, is a top digital marketing
              firm providing services like SEO, SEM, PPC, website development,
              and branding. Known for enhancing online visibility, KB Software
              drives targeted website traffic and boosts ROI with well-planned
              SEO campaigns. Their tailored services focus on long-term growth,
              with a dedicated team that maximizes profits through expert
              marketing and search strategies, ensuring a strong online presence
              for clients.
            </p>
          </Col>
          <Col md={6}>
            <img
              src={socialMediaImg}
              alt="Social Media Management"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* Local SEO Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>3.</span> Local SEO
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software in Zirakpur, India, is a top digital marketing company
              offering SEO, SEM, PPC, website development, and branding. They
              excel in boosting online visibility, driving targeted traffic, and
              increasing ROI. Their local SEO services include keyword
              optimization, Google My Business management, local listings,
              on-page SEO, and review management to enhance local growth.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={localSeoImg}
              alt="Local SEO"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* Marketing Campaigns Section */}
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>4.</span> Marketing Campaigns
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software drives growth and engagement through customized
              digital marketing campaigns. Key strategies include targeted
              social media ads, PPC for immediate traffic, personalized email
              marketing, content marketing for audience conversion, and
              influencer collaborations to expand brand visibility. Each
              campaign aligns with business goals to deliver effective results
              and maximize ROI.
            </p>
          </Col>
          <Col md={6}>
            <img
              src={marketingCampaignsImg}
              alt="Marketing Campaigns"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* PPC Management Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>5.</span> PPC Management
            </h3>
            <p style={{ fontSize: "1rem" }}>
              Drive high-quality traffic to your website with KB Software's
              expert PPC management services. Our team crafts and optimizes
              targeted ads that not only attract the right audience but also
              generate measurable results for your business. With data-driven
              strategies and continuous optimization, we ensure your ads reach
              their full potential, helping you increase visibility, drive
              conversions, and maximize your return on investment.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={ppcManagementImg}
              alt="PPC Management"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DigitalMarketingPage;
