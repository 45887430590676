import React from "react";
import "./Product.css"; // Importing the CSS file
import icon1 from "../../assets/images/product-icons/icon.png";
import jawelry from "../../assets/images/product-icons/jewelry.png";
import icon3 from "../../assets/images/product-icons/icon3.png";
import hotel from "../../assets/images/product-icons/hotel.png";
import icon6 from "../../assets/images/product-icons/icon6.png";
import kbemp from "../../assets/images/product-icons/empare.png";
import kblab from "../../assets/images/product-icons/kbkab.png";
import kbmilk from "../../assets/images/product-icons/kbmilk.png";
import kbschool from "../../assets/images/product-icons/kbschool.png";
import medical from "../../assets/images/product-icons/medical.png";
import moneychash from "../../assets/images/product-icons/moneycash.png";
import kbpro from "../../assets/images/product-icons/kbpro.png";
import kblib from "../../assets/images/product-icons/kblibrabry.png";
import store from "../../assets/images/product-icons/store2.png";
import rapid from "../../assets/images/product-icons/rapid.png";
import kbclinic from "../../assets/images/product-icons/kbclinic.png";
import educational from "../../assets/images/product-icons/educational.png";

const productData = [
  {
    icon: icon1,
    title: "KB Plus",
    des: "India's First GST-ready business software for supermarkets, mobile shops, electronics/FMCG dealers, retailers, wholesalers, and manufacturers.",
  },
  {
    icon: jawelry,
    title: "KB Jewellery ",
    des: "Jewellery shop software with stock management, Girvi management, tag printing, real-time rate updates, ordering, sales/purchase, and financial accounting.",
  },
  {
    icon: icon3,
    title: "KB MLM Software",
    des: "Designed to help businesses manage and automate multi-level marketing operations with compensation plan management, commission calculation, and real-time analytics.",
  },
  {
    icon: medical,
    title: "KB Medical",
    des: "Specialized software for pharmaceutical dealers and distributors, offering stock management and expiry calculation for both wholesale and retail chemists.",
  },
  {
    icon: hotel,
    title: "KB Hotel",
    des: "Comprehensive software for hotels and restaurants with KOT management, table booking, room service, billing, stock management, and production tracking.",
  },
  {
    icon: kbclinic,
    title: "KB Clinic",
    des: "Hospital software covering IPD/OPD, billing, accounting, medicine inventory, stock management, advance payments, and balance facilities.",
  },

  {
    icon: icon6,
    title: "KB Inventory",
    des: "Software designed for rice mills and cotton mills, managing customer bills, rate/weight adjustments, and other sheller industry-specific requirements..",
  },
  {
    icon: kbemp,
    title: "KB Empire",
    des: "Software for shuttering and scaffolding stores, enabling accurate rental bills, stock management, and integrated financial accounting control.",
  },
  {
    icon: store,
    title: "KB STORE",
    des: "Apparel and footwear software for retail showrooms, managing stock by size, color, and article, with barcode printing for tags.",
  },
  {
    icon: educational,
    title: "KB Education",
    des: "Software for educational institutions, managing enquiries, admissions, fee payments, libraries, attendance, and timetables for streamlined administration.",
  },
  {
    icon: kblib,
    title: "KB Library",
    des: "Library management software with book records, issue/receipt tracking, sticker printing, library cards, pending books list, book ledger, and user security features.",
  },
  {
    icon: kbmilk,
    title: "KB Milk Man",
    des: "Software for dairy farms to maintain farmer records, calculate FAT-SNF-CLR, generate 10-day bills, manage stock, and track profit/loss.",
  },
  {
    icon: moneychash,
    title: "KB Sigma",
    des: "Sigma software for non-banking financial companies, tracking customer and hypothecated vehicle records, and offering comprehensive management tools.",
  },
  {
    icon: kbschool,
    title: "KB School",
    des: "School and college management software handling enquiry, admission, fee collection, library, attendance, timetable, certificates, ID cards, and reminders.",
  },
  {
    icon: kbpro,
    title: "KB Pro",
    des: "Software for GST billing with sale reports, automatic backups, outstanding reports, ledger management, SMS templates, and more.",
  },
  {
    icon: kblab,
    title: "KB Smart Lab",
    des: "Laboratory software to maintain patient records and generate accurate, customizable test reports based on specific tests and formulations.",
  },
  {
    icon: rapid,
    title: "KB Rapid",
    des: "Cold storage management software that tracks customer stock by lot number and rack, with stock movement and material inward/outward recording.",
  },
];

const Product = () => {
  return (
    <div className="container my-5">
      <div className="card-container mt-6">
        {/* Static card outside the rotating cards */}
        <div className="static-cards card-wrapper">
          <div className="card-inner">
            <div className="card-front my-0">
              <h4 className="our">
                Our <span>Products</span>
              </h4>
              <p>
                At KB Software, we offer a range of cutting-edge software
                solutions designed to enhance productivity and streamline
                operations. Our products to various industries, ensuring
                tailored functionality to meet your specific needs.
              </p>
            </div>
          </div>
        </div>

        {/* Rotatable cards */}
        {productData.map((product, index) => (
          <div key={index} className="card-wrapper rotatable-card">
            <div className="card-inner">
              <div className="card-front">
                <img src={product.icon} alt="Product icon" />
                <h5 className="my-3 title">{product.title}</h5>
              </div>
              <div className="card-back">
                <h3>{product.title}</h3>
                <p>{product.des}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
