import "./DesBanner.css";

const DesignBanner = () => {
  return (
    <>
      <div className="design-banner-container">
        <div className="banner-text-overlay">Designing</div>
      </div>
    </>
  );
};
export default DesignBanner;
