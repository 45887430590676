// src/components/Banner.js
import React from "react";
import "./ContactBanner.css";

const ConBanner = () => {
  return (
    <>
      <div className="contact-main-banner">
        <div className="contact-main-banner-heading">
          <h1>Contact Us</h1>
        </div>
      </div>
    </>
  );
};

export default ConBanner;
