import React from "react";
import Banner from "../components/contact/Banner/ContBanner";
import Address from "../components/contact/Address/Address";
import SocialMedia from "../components/contact/SocialMedia/SocialMedia";
// import { Container } from "react-bootstrap";
import Faq from "../components/contact/FAQ/Faq";
// import { DiEnvato, DiJava } from "react-icons/di";

const Contact = () => {
  return (
    <div className="my-0">
      <Banner />
      <SocialMedia />
      <Address />
      <Faq />
    </div>
  );
};

export default Contact;
