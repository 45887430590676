import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../assets/images/logo.jpg";
import ServiceDropdown from "../Service/ServiceHeaders/ServiceHeader";

import "./Header.css";

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleServiceClick = (e) => {
    e.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };
  return (
    <>
      <Navbar bg="white" expand="lg" className="border-bottom custom-navbar">
        <Container>
          {/* Logo */}
          <Navbar.Brand href="/" className="logo-container">
            <img src={logo} alt="Kharido Befikar Logo" className="logo-img" />
          </Navbar.Brand>

          {/* Navbar Toggle Button for Mobile */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          {/* Navigation Links */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/" className="nav-item">
                Home
              </Nav.Link>
              <Nav.Link href="/about" className="nav-item">
                About
              </Nav.Link>
              <Nav.Link
                href="#"
                className="nav-item"
                onClick={handleServiceClick}
              >
                Services
              </Nav.Link>
              <Nav.Link href="/team" className="nav-item">
                Team
              </Nav.Link>
              <Nav.Link href="/project" className="nav-item">
                Projects
              </Nav.Link>
              <Nav.Link href="/contact" className="nav-item">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ServiceDropdown show={showDropdown} onHide={handleCloseDropdown} />
    </>
  );
}

export default Header;
