import Teams from "../components/Team/Teams";

const Team = () => {
  return (
    <>
      <Teams />
    </>
  );
};
export default Team;
