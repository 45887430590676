import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaEnvelope, FaPhone, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./SocialMedia.css"; // Your custom CSS

const ContactSocialMedia = () => {
  const [activeIcon, setActiveIcon] = useState(null);

  // Function to handle mouse enter
  const handleMouseEnter = (icon) => {
    setActiveIcon(icon);
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setActiveIcon(null);
  };

  return (
    <Container className="my-5">
      <Row className="text-center">
        {/* Email Section */}
        <Col
          md={3}
          className="contact-item"
          onMouseEnter={() => handleMouseEnter("email")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`icon-circle ${
              activeIcon === "email" ? "active-rectangle" : ""
            }`}
          >
            <FaEnvelope size={24} className="icon-style" />
          </div>
          <h5 className="mt-3">Email</h5>
          <p>kharidobefikar@gmail.com</p>
        </Col>

        {/* Mobile Number Section */}
        <Col
          md={3}
          className="contact-item"
          onMouseEnter={() => handleMouseEnter("mobile")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`icon-circle ${
              activeIcon === "mobile" ? "active-rectangle" : ""
            }`}
          >
            <FaPhone size={24} className="icon-style" />
          </div>
          <h5 className="mt-3">Mobile</h5>
          <p>+91 8320121716</p>
        </Col>

        {/* LinkedIn Section */}
        <Col
          md={3}
          className="contact-item"
          onMouseEnter={() => handleMouseEnter("linkedin")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`icon-circle ${
              activeIcon === "linkedin" ? "active-rectangle" : ""
            }`}
          >
            <a
              href="https://www.linkedin.com/in/kbsoftware-solution-a1ba01338/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <FaLinkedin size={24} className="icon-style" />
            </a>
          </div>
          <h5 className="mt-3">LinkedIn</h5>
          <p>kbsoftware-solution</p>
        </Col>

        {/* Twitter Section */}
        <Col
          md={3}
          className="contact-item"
          onMouseEnter={() => handleMouseEnter("twitter")}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`icon-circle ${
              activeIcon === "twitter" ? "active-rectangle" : ""
            }`}
          >
            <a
              href="https://x.com/kbsoftware29776"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <FaTwitter size={24} className="icon-style" />
            </a>
          </div>
          <h5 className="mt-3">Twitter</h5>
          <p>kbsoftware29776</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSocialMedia;
