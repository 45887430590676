import Projects from "../components/Project/Projects";

const Projetc = () => {
  return (
    <>
      <Projects />
    </>
  );
};
export default Projetc;
