import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt, FaClock, FaHeadset } from "react-icons/fa";

const Address = () => {
  return (
    <div>
      <h2 className="text-center mb-4">
        About <span style={{ color: "#052c65" }}>Us</span>
      </h2>

      {/* Horizontal Row of Equal-Sized Cards */}
      <Row className="mb-4 mx-4 gy-4"> 
        <Col md={4} xs={12}> {/* `xs={12}` makes each card full width in mobile view */}
          <Card
            className="address-card"
            style={{
              height: "100%",
              border: "none",
              backgroundColor: "#e9ecef",
              borderRadius: "10px",
            }}
          >
            <Card.Body className="text-center">
              <FaMapMarkerAlt size={30} color="#007bff" className="mb-2" />
              <Card.Title>Our Address</Card.Title>
              <Card.Text>KHARIDO BEFIKAR</Card.Text>
              <Card.Text>
                Ofc No. 1429, 14th Floor, Block D, CCC, VIP road, Zirakpur
                140603
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xs={12}>
          <Card
            className="address-card"
            style={{
              height: "100%",
              border: "none",
              backgroundColor: "#e9ecef",
              borderRadius: "10px",
            }}
          >
            <Card.Body className="text-center">
              <FaClock size={30} color="#007bff" className="mb-2" />
              <Card.Title>Office Timing</Card.Title>
              <Card.Text>Monday to Saturday: 9:00 AM - 6:00 PM</Card.Text>
              <Card.Text>Sunday: Closed</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xs={12}>
          <Card
            className="address-card"
            style={{
              height: "100%",
              border: "none",
              backgroundColor: "#e9ecef",
              borderRadius: "10px",
            }}
          >
            <Card.Body className="text-center">
              <FaHeadset size={30} color="#007bff" className="mb-2" />
              <Card.Title>Customer Support</Card.Title>
              <Card.Text>Available: 24/7</Card.Text>
              <Card.Text>Call us anytime for assistance!</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Add custom styles */}
      <style jsx>{`
        .address-card {
          transition: transform 0.3s, box-shadow 0.3s;
        }
        .address-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        }
      `}</style>
    </div>
  );
};

export default Address;
