import React from "react";
import Banner from "../components/Banner/Banner";
import Product from "../components/Products/Product";
// import Marketing from '../components/Marketing/Marketing';
import Connect from "../components/Connect/Connect";
import Feature from "../components/Feature/Feature";

const Home = () => {
  return (
    <div>
      <Banner />
      <Product />
      {/* <Marketing /> */}
      <Connect />
      <Feature />
    </div>
  );
};

export default Home;
