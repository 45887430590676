import React from "react";
import logo from "../assets/images/whatsapp2.png";

const WhatsAppButton = () => {
  const whatsappNumber = "+919104718383";
  const message = "Hello, I would like to inquire about...";

  const handleClick = () => {
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="fixed bottom-4 right-4" style={{ zIndex: 1000 }}>
      <button
        onClick={handleClick}
        aria-label="Contact us on WhatsApp"
        style={{
          background: "none",
          padding: 0,
          border: "none",
          boxShadow: "none",
        }}
      >
        <img
          src={logo}
          alt="WhatsApp Logo"
          style={{
            width: "60px", 
            height: "60px", 
            borderRadius: "50%", 
          }}
        />
      </button>
    </div>
  );
};

export default WhatsAppButton;
