import React from "react";
import Banner from "../components/About/Banner/Banner";
import AboutContents from "../components/About/About/AboutContents";

function About() {
  return (
    <>
      <Banner />
      <AboutContents />
    </>
  );
}

export default About;
