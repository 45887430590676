import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import image1 from "../../../assets/images/image1.jpg";
import image2 from "../../../assets/images/image2.png";
import image3 from "../../../assets/images/image3.jpg";
import "./AboutContents.css";

const AboutContents = () => {
  return (
    <>
      <Container fluid className="About-Content">
        <Row className="justify-content-center text-center">
          <Col xs={12}>
            <h4>
              Welcome to <span className="span">KB Software </span>Solutions
            </h4>
          </Col>
        </Row>

        {/* First Content and Image Section */}
        <Row className="align-items-center px-4">
          {/* Content Column */}
          <Col xs={12} md={6} className="content order-2 order-md-1">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <p>
                At KB Software Solutions, we are dedicated to transforming your
                business through innovative technology. With years of experience
                in the industry, we specialize in providing custom software
                development, mobile applications, and GST solutions tailored to
                meet the unique needs of your organization. Our goal is to
                streamline your operations and enhance overall efficiency,
                allowing you to focus on what matters most—growing your
                business.
              </p>
            </motion.div>
          </Col>

          {/* Image Column */}
          <Col
            xs={12}
            md={6}
            className="img-container order-1 order-md-2 mb-4 mb-md-0"
          >
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <img src={image1} alt="loading..." className="img-fluid" />
            </motion.div>
          </Col>
        </Row>

        {/* Second Content and Image Section */}
        <Row className="align-items-center px-4 my-4">
          {/* Image Column */}
          <Col
            xs={12}
            md={6}
            className="img-container order-1 order-md-1 mb-4 mb-md-0"
          >
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <img src={image2} alt="loading..." className="img-fluid" />
            </motion.div>
          </Col>

          {/* Content Column */}
          <Col xs={12} md={6} className="content order-2 order-md-2">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <p>
                Our comprehensive range of services includes software
                development, where we create robust and scalable applications
                that drive productivity and improve workflows. We also offer web
                development services to establish a strong online presence,
                ensuring your website is both user-friendly and visually
                appealing. Additionally, our domain registration service
                simplifies the process of securing your online identity.
              </p>
            </motion.div>
          </Col>
        </Row>

        {/* Third Content and Image Section */}
        <Row className="align-items-center px-4">
          {/* Content Column */}
          <Col xs={12} md={6} className="content order-2 order-md-1">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <p>
                Understanding the importance of mobile connectivity, we develop
                Android apps that engage your customers and provide them with
                seamless access to your services. Our bulk SMS service ensures
                effective communication with your clients, enabling you to send
                timely updates and promotional messages effortlessly. To top it
                off, our 24/7 support team is always available to assist you,
                ensuring that you receive prompt assistance whenever you need
                it.
              </p>
            </motion.div>
          </Col>

          {/* Image Column */}
          <Col
            xs={12}
            md={6}
            className="img-container order-1 order-md-2 mb-4 mb-md-0"
          >
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <img src={image3} alt="loading..." className="img-fluid" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutContents;
