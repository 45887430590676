import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import webDevelopmentImg from "../assets/images/web1.jpg";
import androidDevelopmentImg from "../assets/images/web2.png";
import ecommerceWebsiteImg from "../assets/images/web3.jpg";
import staticWebsiteImg from "../assets/images/web4.png";
import crmWebsiteImg from "../assets/images/web5.jpg";
import Banner from "../components/Service/banner/Development/banner";
const ServicePage = () => {
  return (
    <div style={{ color: "#4A4A4A", padding: "1rem 0" }}>
      {/* Banner Section */}
      <Banner />

      <Container>
        <h2
          className="text-center "
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#052C65",
            marginTop: "2rem",
          }}
        >
          Development
        </h2>
        <p
          style={{
            fontSize: "1rem",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Your website serves as the online representation of your company, and
          we at KB Software make sure it looks great. Our skilled staff creates
          user-friendly, responsive, and aesthetically pleasing websites that
          are suited to the requirements of your company. We concentrate on
          developing captivating experiences that enthrall visitors and provide
          outcomes, whether you're searching for a modern business website or an
          active e-commerce platform. Allow us to assist you in developing a
          website that will enhance your company's online presence while also
          looking fantastic!
        </p>{" "}
        {/* Web Development Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>1.</span>{" "}
              <span style={{ color: "#052C65" }}>Web Development</span>
            </h3>{" "}
            <p style={{ fontSize: "1rem" }}>
              We offer custom website development options. We have extensive
              experience in developing websites of all types, budgets, and
              complexities. Our approach ensures that we create tailored
              solutions to meet your business requirements.
            </p>
          </Col>
          <Col md={6}>
            <img
              src={webDevelopmentImg}
              alt="Web Development"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        {/* Android Development Section */}
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>2.</span>{" "}
              <span style={{ color: "#052C65" }}>Android Development</span>
            </h3>{" "}
            <p style={{ fontSize: "1rem" }}>
              Looking to build an Android app? We create easy-to-use, custom
              apps that fit your business perfectly. Whether it's for your
              store, service, or any other idea, we ensure smooth and functional
              designs.
            </p>
          </Col>
          <Col md={6}>
            <img
              src={androidDevelopmentImg}
              alt="Android Development"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        {/* E-Commerce Website Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>3.</span>{" "}
              <span style={{ color: "#052C65" }}>E-Commerce Website</span>
            </h3>{" "}
            <p style={{ fontSize: "1rem" }}>
              Ready to take your business online? We offer easy-to-use, custom
              e-commerce solutions to help you sell your products. Our seamless
              design ensures secure payments and a smooth shopping experience.
            </p>
          </Col>
          <Col md={6}>
            <img
              src={ecommerceWebsiteImg}
              alt="E-Commerce Website"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        {/* Static Website Section */}
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>4.</span>{" "}
              <span style={{ color: "#052C65" }}>Dynamic Website</span>
            </h3>{" "}
            <p style={{ fontSize: "1rem" }}>
              A static website is the simplest way to showcase your business or
              personal brand online. It's easy to set up and perfect for those
              who don’t need frequent updates. At KB Software, we offer
              affordable static website solutions tailored to your specific
              needs. Static websites serve as effective digital brochures,
              allowing you to present your products and services clearly to
              potential customers. They are ideal for individuals and small
              businesses looking to establish their online presence or provide
              essential contact information.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={staticWebsiteImg}
              alt="Static Website"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        {/* CRM/HRM Website Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>5.</span>{" "}
              <span style={{ color: "#052C65" }}>Static Website</span>
            </h3>{" "}
            <p style={{ fontSize: "1rem" }}>
              In today’s fast-paced business environment, effective management
              of customer relationships and human resources is essential for
              success. Our integrated CRM and HRM solutions are designed to
              streamline your operations, enhance productivity, and foster
              better relationships with both your customers and employees.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={crmWebsiteImg}
              alt="CRM/HRM Website"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServicePage;
