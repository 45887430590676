// src/components/CarouselComponent/CarouselComponent.js
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "./Banner.css";
import homepagebanner1 from "../../assets/images/banner/Home page banners/home page banner1-software.png";
import homepagebanner2 from "../../assets/images/banner/Home page banners/home page banner2-hotel.png";
import homepagebanner3 from "../../assets/images/banner/Home page banners/home page banner3-education.png";
import homepagebanner4 from "../../assets/images/banner/Home page banners/home page banner4-clinic.png";
import homepagebanner5 from "../../assets/images/banner/Home page banners/home page banner5-accounting.png";
import homepagebanner6 from "../../assets/images/banner/Home page banners/home page banner6-jewelry.png";
import homepagebanner7 from "../../assets/images/banner/Home page banners/home page banner7-mlm.png";

const CarouselComponent = () => {
  const images = [
    homepagebanner1,
    homepagebanner2,
    homepagebanner3,
    homepagebanner4,
    homepagebanner5,
    homepagebanner6,
    homepagebanner7,
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <Carousel
      indicators={false}
      className="custom-carousel"
      interval={2000}
      pause={false}
      activeIndex={activeIndex}
      onSelect={handleSelect}
    >
      {images.map((image, index) => (
        <Carousel.Item key={index} className="carousel-item ">
          <img
            className="d-block w-100 carousel-image"
            src={image}
            alt={`Slide ${index + 1}`}
          />
          {/*   <Carousel.Caption>
            <div className="caption-text">
              <h2>HOTEL & RESTAURANT</h2>
              <h3>SOFTWARE</h3>
            </div>
          </Carousel.Caption>
           */}
        </Carousel.Item>
      ))}
      <div className="carousel-indicator">
        {images.map((_, index) => (
          <span
            key={index}
            className={`indicator-line ${
              index === activeIndex ? "active" : ""
            }`}
          ></span>
        ))}
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
