import { useState, useEffect } from "react"; // Removed duplicate React import
import { Container, Row, Col } from "react-bootstrap";
import "./Feature.css";

const FeatureSection = () => {
  // State variables defined at the top level of the component
  const [lineWidth, setLineWidth] = useState("0%");
  const [isAnimated, setAnimated] = useState(true);

  useEffect(() => {
    if (isAnimated) {
      const timer = setTimeout(() => {
        setLineWidth("100%");
        // Reset the line width after it expands

        setTimeout(() => {
          setLineWidth("0%");
        }, 2000); // Matches the transition time
      }, 2000); // Initial delay before animation starts

      return () => clearTimeout(timer);
    }
  }, [lineWidth, isAnimated]);

  return (
    <Container className="feature-section text-center">
      <h2 className="feature-title">
        GST SOFTWARE <span className="feature-highlight">FEATURES</span>
      </h2>
      <hr
        className="feature-underline"
        style={{ width: lineWidth, transition: "width 3s ease-in-out" }}
      />

      <Row>
        <Col md={4} className="feature-item">
          <div className="feature-icon">
            <i className="bi bi-cloud-upload"></i>
          </div>
          <h5>Auto Backup</h5>
          <p>
            Save Backup in Financial year wise, Monthwise, Daywise & Direct
            Email.
          </p>
        </Col>
        <Col md={4} className="feature-item">
          <div className="feature-icon">
            <i className="bi bi-whatsapp"></i>
          </div>
          <h5>Send Bill on Whatsapp</h5>
          <p>You Can Share Bills & Reports on Whatsapp within Single Click.</p>
        </Col>
        <Col md={4} className="feature-item">
          <div className="feature-icon">
            <i className="bi bi-printer"></i>
          </div>
          <h5>Cheque Printing</h5>
          <p>
            Our Software provides facility to Print Cheques Directly from the
            software.
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={4} className="feature-item">
          <div className="feature-icon">
            <i className="bi bi-chat-left-text"></i>
          </div>
          <h5>SMS Plugin</h5>
          <p>Create your Own SMS Templates, Reminders etc.</p>
        </Col>
        <Col md={4} className="feature-item">
          <div className="feature-icon">
            <i className="bi bi-file-earmark-text"></i>
          </div>
          <h5>E-Way Bill</h5>
          <p>Easily Generate JSON files & Get your E-way Bill.</p>
        </Col>
        <Col md={4} className="feature-item">
          <div className="feature-icon">
            <i className="bi bi-file-earmark-spreadsheet"></i>
          </div>
          <h5>Import Data From Excel</h5>
          <p>
            Easily Import & Export Data in any Format (.pdf, .xls, .doc, .mdb
            etc.).
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FeatureSection;
