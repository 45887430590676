import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Whatsapp from "./components/whatsapp";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Team from "./pages/Team";
import Project from "./pages/Project";
import DevelopmentPage from "./pages/DevelopmentPage";
import "./App.css";
import DesigningPage from "./pages/DesigningPage";
import Contact from "./pages/Contact";
import DigitalMarketingPage from "./pages/DigitalMarketingPage";

const App = () => {
  return (
    <Router>
      <Header />
      <Whatsapp />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/project" element={<Project />} />
        <Route exact path="/development" element={<DevelopmentPage />} />
        <Route
          exact
          path="/digital-marketing"
          element={<DigitalMarketingPage />}
        />
        <Route exact path="/designing" element={<DesigningPage />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
