import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import graphicDesigningImg from "../assets/images/graphic design.png";
import webDesigningImg from "../assets/images/webdesign.png";
import logoDesigningImg from "../assets/images/logo design.png";
import figmaDesigningImg from "../assets/images/figma design.png";
import websiteRedesigningImg from "../assets/images/website redesigning.png";
import enhanceWebDesigningImg from "../assets/images/enhance website design.png";
import Banner from "../components/Service/banner/Desgining/DesgBanner";

const DesigningPage = () => {
  return (
    <div
      style={{ backgroundColor: "white", color: "#4A4A4A", padding: "1rem 0" }}
    >
      <Banner />
      <Container>
        <h2
          className="text-center"
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#052C65",
            marginTop: "2rem",
          }}
        >
          Designing
        </h2>
        <p
          style={{
            fontSize: "1rem",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Enhance your brand’s visual appeal with our diverse range of design
          services. Our creative team at KB Software provides professional and
          eye-catching designs tailored to your business needs. From logo
          creation to full-scale web redesigns, we deliver quality visuals that
          leave a lasting impression.
        </p>

        {/* Graphic Designing Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{ fontSize: "1.75rem", fontWeight: "600" }}
            >
              <span style={{ color: "black" }}>1.</span>{" "}
              <span style={{ color: "#052C65" }}>Graphic Designing</span>
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software offers professional graphic design services to create
              visually compelling and brand-aligned designs. From logos and
              brochures to social media graphics and website visuals, our team
              delivers creative solutions that enhance brand identity and
              communication. Each design is tailored to meet your business
              needs, ensuring high-quality and impactful visuals.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={graphicDesigningImg}
              alt="Graphic Designing"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* Web Designing Section */}
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>2.</span> Web Designing
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software offers professional graphic design services to create
              visually compelling and brand-aligned designs. From logos and
              brochures to social media graphics and website visuals, our team
              delivers creative solutions that enhance brand identity and
              communication. Each design is tailored to meet your business
              needs, ensuring high-quality and impactful visuals.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={webDesigningImg}
              alt="Web Designing"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* Logo Designing Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>3.</span> Logo Designing
            </h3>
            <p style={{ fontSize: "1rem" }}>
              Professional logo design services are provided by KB Software to
              assist companies in developing a powerful and enduring brand
              identity. In order to make sure the logo appropriately captures
              the spirit of the company, our talented designers collaborate
              closely with customers to fully grasp their vision, values, and
              target market. We concentrate on designing logos that are
              aesthetically pleasing, adaptable, and instantly identifiable
              across a range of mediums, including print and digital{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={logoDesigningImg}
              alt="Logo Designing"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* Figma Designing Section */}
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>4.</span> Figma Designing
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software offers expert Figma design services for creating
              intuitive, high-quality user interfaces (UI) and user experiences
              (UX). Using Figma, our design team collaborates seamlessly to
              build responsive, pixel-perfect designs for websites and mobile
              apps. We focus on user-centric designs, ensuring that your digital
              products are both visually appealing and highly functional. Our
              Figma designs are tailored to your business needs, allowing for
              easy updates, real-time collaboration, and smooth development
              handoffs, resulting in faster project delivery and a cohesive
              design process.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={figmaDesigningImg}
              alt="Figma Designing"
              className="img-fluid rounded"
            />
          </Col>
        </Row>

        {/* Website Redesigning Section */}
        <Row
          className="align-items-center mb-4 p-3"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>5.</span> Website Redesigning
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software offers professional website redesigning services to
              refresh and enhance your existing website. Our team works to
              improve user experience (UX), visual appeal, and overall
              performance by updating layouts, optimizing content, and
              integrating the latest design trends. We focus on creating a
              responsive, mobile-friendly site that aligns with your brand’s
              goals while improving site speed, SEO, and user engagement.
              Whether you’re looking to modernize the design, add new features,
              or improve functionality.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={websiteRedesigningImg}
              alt="Website Redesigning"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
        <Row
          className="align-items-center mb-4 p-3 flex-md-row-reverse"
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Col md={6} className="mb-3 mb-md-0">
            <h3
              className="mb-3"
              style={{
                fontSize: "1.75rem",
                fontWeight: "600",
                color: "#052C65",
              }}
            >
              <span style={{ color: "black" }}>6.</span> Enhance Web Designing
            </h3>
            <p style={{ fontSize: "1rem" }}>
              KB Software specializes in enhancing web design to create visually
              captivating, user-friendly, and high-performing websites. Our
              approach focuses on improving the overall user experience (UX) by
              optimizing layout, navigation, and design elements to ensure
              smooth functionality across all devices. We also enhance website
              speed, responsiveness, and SEO to boost search engine rankings and
              user engagement. By incorporating modern design trends, intuitive
              interfaces, and clear calls-to-action, we ensure that your website
              not only looks great but also drives conversions.{" "}
            </p>
          </Col>
          <Col md={6}>
            <img
              src={enhanceWebDesigningImg}
              alt="Figma Designing"
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DesigningPage;
