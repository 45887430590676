import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { GiSupersonicArrow } from "react-icons/gi";

const faqData = [
  {
    title: "Why Choose KB Software Solutions ?",
    description:
      "KB Software Solutions offers customized, innovative, and reliable technology solutions to help your business grow. With expertise in software development, mobile apps, web design, and digital marketing, we deliver tailored solutions that meet your specific needs. Our commitment to quality, timely delivery, and excellent customer support ensures a seamless experience, empowering businesses to achieve their goals efficiently and cost-effectively.",
  },
  {
    title: "What Technologies Do We Use for Software Development?",
    description:
      "At KB Software Solutions, we leverage the latest technologies to deliver high-quality software solutions. Our tech stack includes front-end frameworks like React, Angular, and Vue.js, and back-end technologies such as Node.js, Python, Java, and PHP. For mobile app development, we use React Native, Swift, and Kotlin.",
  },
  {
    title: "How do you ensure software quality?",
    description:
      "We follow a rigorous quality assurance process that includes automated and manual testing throughout the development cycle. This ensures that the software is free from bugs and performs optimally. We also follow coding standards and best practices to deliver high-quality, reliable solutions.",
  },
  {
    title: "Will I have access to my project’s progress during development?",
    description:
      "Yes! We believe in transparent communication and provide regular updates during the development process. You will have access to project milestones, reports, and meetings to ensure everything is on track.",
  },
  {
    title: "Do you provide ongoing support after the project is completed?",
    description:
      "Yes, we offer comprehensive post-launch support, including maintenance, troubleshooting, software updates, and enhancements. Our team ensures that your software continues to run smoothly after deployment.",
  },
  {
    title: "Can you assist with digital marketing for my software or app?",
    description:
      "Yes, we provide full digital marketing services to help you promote your software or app. This includes SEO, social media marketing, PPC, content marketing, and email campaigns, helping you reach your target audience and increase user engagement.",
  },
];

function FAQHeading() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container className="mt-12 position-relative">
      <h2
        className="text-center mb-4 text-bold"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ transition: "color 0.3s ease" }}
      >
        <span style={{ color: isHovered ? "#052c65" : "black" }}>
          Frequently{" "}
        </span>
        <span style={{ color: isHovered ? "black" : "#052c65" }}>Asked</span>
        <span style={{ color: isHovered ? "#052c65" : "black" }}>
          {" "}
          Questions
        </span>
      </h2>
      <p className="text-center mt-2">
        Here are some of the Roomsy frequently asked questions
      </p>
      <Row className="gy-4 position-relative">
        {faqData.map((item, index) => (
          <Col md={6} key={index} className="position-relative">
            <Card className="border-0">
              <Card.Body>
                <div className="d-flex align-items-start">
                  <div className="icon-container me-3">
                    {/* Only the last item has a larger icon */}
                    {index === faqData.length - 1 ? (
                      <GiSupersonicArrow size="3em" color="#007bff" />
                    ) : (
                      <GiSupersonicArrow size="2em" color="#007bff" />
                    )}
                  </div>
                  <div>
                    <Card.Title className="fw-bold" style={{ color: "#333" }}>
                      {item.title}
                    </Card.Title>
                    <Card.Text style={{ color: "#555" }}>
                      {item.description}
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
              {/* Divider for all items except the last one */}
              {index < faqData.length - 1 && (
                <div className="divider position-absolute top-0 start-50 translate-middle-x"></div>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default FAQHeading;
